import axios from 'axios'
import { Message } from 'element-ui'
import store from '../store'
import errorMsg from './errorMsg.js'
import Qs from 'qs'
import NProgress from 'nprogress'
import router from '@/router/index'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API.indexOf('http') !== -1 ? process.env.VUE_APP_BASE_API : window.location.origin + '/api',
  timeout: 50000                  // 请求超时时间
})

async function transformConfig(config) {

  if (config.refreshToken && store.getters.token) {

    // console.log('token---' + store.getters.token )
    let timestamp = (Date.parse(new Date())) / 1000
    // console.log('token-exp---' + store.getters.exp, timestamp)
    if (Number(store.getters.exp) - timestamp <= 1800) {
      await getRefresh()
    }
  }
  if (store.getters.token) {
    config.headers['Authorization'] = 'bearer ' + store.getters.token // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
  }

  config.transformRequest = function (data) {
    if (data && data.isUploadFile) {
      let params = new FormData()
      for (var key in data) {
        if (key !== 'isUploadFile') {
          params.append(key, data[key])
        }
      }
      return params
    } else {
      return Qs.stringify(data)
    }
  }

  return config
}

// request拦截器
service.interceptors.request.use(config => {

  return transformConfig(config)

}, error => {
  // Do something with request error
  return Promise.reject(error)
})

// 刷新token
function getRefresh() {
  return service.get('/token/refresh', {
    params: {
      token_refresh: true
    }
  }).then(function (response) {
    console.log('getRefresh', response)
    if (response.headers.authorization) {
      store.dispatch('setToken', response.headers.authorization)
    }
  })
}

// response拦截器
service.interceptors.response.use(response => {
  if (response.config.method != 'get') {
    Message.closeAll()
  }
  return response;
  /**
   * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
   * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
   */
}, error => {
  const loginPath = process.env.VUE_APP_PREFIXES ? `/${process.env.VUE_APP_PREFIXES}/login` : '/login'
  Message.closeAll()
  var msg = ''
  // 如果token已经过期，则刷新token
  if (error.response.data.error.message === 'Token has expired and can no longer be refreshed') {
    msg = '登录已失效，请重新登录'
  } else if (error.response.data.error.message === 'The token has been blacklisted' || error.response.data.error.message === 'Token has expired') {
    msg = '当前页面已过期，请刷新'
  } else if (error.response && error.response.status === 403 && error.response.data.error.message == 'access denied, username password are not match') {
    msg = '请输入正确的账号和密码'
  } else if (error.response && error.response.status === 403 && error.response.data.error.message == 'Token Signature could not be verified.') {
    router.push({ path: loginPath })
    NProgress.done()
  } else if (error.response && error.response.status === 401) {
    const pathPrefixes = window.location.pathname.split('/')[1] || '';
    console.log(333,pathPrefixes)
    const loginPath = pathPrefixes ? `/${pathPrefixes}/login` : '/login' // const loginPath = this.path_prefixes ? `/${this.path_prefixes}/login` : '/login'
    msg = '登陆已过期，请重新登陆'
    router.push({ path: loginPath })
    NProgress.done()
  } else {
    if (error.response && error.response.data.error.code && errorMsg['code' + error.response.data.error.code]) {
      msg = errorMsg['code' + error.response.data.error.code]
    } else {
      msg = error.response.data.error.message
    }
  }

  if (!msg) {
    msg = '系统繁忙，请重试'
  }

  Message({ message: msg, type: 'error', duration: 3 * 1000 })

  return Promise.reject(error)
}
)

export default service
